.selected-day {
    background-color: lightblue !important;
    /*border: 2px solid blue;*/
  }

/*#fc-dom-1 {*/
/*    font-size: 11.25px; !* Уменьшено на 25% *!*/
/*}*/
/*:root {*/
/*    --fc-event-bg-color: #ffeb3b; !* Новый цвет фона *!*/
/*    --fc-event-border-color: #f57c00; !* Новый цвет границы *!*/
/*}*/

.fc-toolbar .fc-button {
    font-size: 11px; /* Уменьшено на 25% */
    padding: 3.75px 9px 5px 5px; /* Уменьшено на 25% */
    /*background-color: #1976d2;*/
    color: white;
    border: none;
    border-radius: 3px; /* Уменьшено на 25% */
    /*margin: 3px;*/
}

/* Стили для кнопок при наведении */
.fc-toolbar .fc-button:hover {
    /*background-color: #1565c0;*/
}

/* Размеры календаря */
.fc {
    max-width: 52.5%;
    height: 375px;
    margin: 0 auto;
    overflow: hidden;
}

.fc-media-screen {
    width: 100%; /* Уменьшено на 25% от 100% */
    max-width: 900px; /* Уменьшено на 25% от 1200px */
    font-size: 12px;

}


.fc-theme-standard {
    background-color: #f9f9f9;
    border-radius: 6px; /* Уменьшено на 25% */
    padding: 7.5px; /* Уменьшено на 25% */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Уменьшена интенсивность тени */
}

.MuiBox-root.css-6su6fj {
    padding: 5px; /* Внутренние отступы */
}
.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border-radius: 12px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #F2EDE7; /* Цвет фона */
    border-color: #F2EDE7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень */
    padding: 16px; /* Отступ */

}
.fc-event border-color{
    color: #F2EDE7;
}
.fc-event bg-color{
    color: #F2EDE7;
}
